<template>
  <div class="col-12 mt-3 px-1">
    <b-tabs>
      <b-tab title="基本設定" active>
        <v-apptype-base/>
      </b-tab>
      <b-tab
        v-for="(page, index) in pages" :key="index">
        <template v-slot:title>
          {{page.name || `新しいページ${index+1}`}}
          <button type="button"
            class="close ml-1"
            @click="removePage(index)"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </template>
        <v-apptype-page
          :page="index+1"/>
      </b-tab>
      <template v-slot:tabs-end
        v-if="canAddPage">
        <b-nav-item @click.prevent="addPage" href="#"><b>+</b></b-nav-item>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import ApptypeBase from '@/components/cms/contents/apptypes/Base.vue';
import ApptypePage from '@/components/cms/contents/apptypes/Page.vue';

export default {
  name: 'CmsContentsApptypeBox',
  components: {
    'v-apptype-base': ApptypeBase,
    'v-apptype-page': ApptypePage,
  },
  data() {
    return {
      // 追加可能な最大ページ数
      maxPage: 10,
    };
  },
  methods: {
    addPage() {
      if (this.canAddPage) {
        this.$store.commit('cmsContentsApptypePage/addPage');
      }
    },
    async removePage(index) {
      if (await this.confirm('このページを削除してもよろしいですか。')) {
        this.$store.commit('cmsContentsApptypePage/removePage', index);
      }
    },
  },
  computed: {
    pages() {
      return this.$store.state.cmsContentsApptypePage.pages;
    },
    canAddPage() {
      return this.pages.length < this.maxPage;
    },
  },
};
</script>

<style scoped>
  #mainWrap {
    border: solid 1px;
    /* height: 400px;
    overflow-y: scroll; */
    overflow-x: hidden;
  }
</style>
