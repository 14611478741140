<template>
  <div class="mx-auto col-10 mt-4">
    <div class="row col-12">
      <p class="h5" v-if="!id">申請タイプ管理 - 登録</p>
      <p class="h5" v-if="id">申請タイプ管理 - 編集</p>
    </div>

    <div id="elementaryWrap" class="mt-2">
      <b-row class="mt-2">
        <b-col sm="3" class="inputLabel">
          <label>名称</label>
        </b-col>
        <b-col sm="5">
          <b-form-input placeholder="入力してください。"
            :value="name"
            @input="updateName"
            ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="3" class="inputLabel">
          <label>年度</label>
        </b-col>
        <b-col sm="5">
          <v-form-select
            :value="year"
            @input="updateYear"
            :options="yearOptions"/>
        </b-col>
      </b-row>
    </div>

    <v-apptype-box/>

    <div class="bottonArea mt-3 mb-3 flex flexCenter">
      <b-button class="btn btn-lg mr-4" to="/cms/contents/applications">戻る</b-button>
      <b-button
        @click="save"
        variant="info"
        class="btn btn-primary btn-lg mr-4"
        >送信</b-button>
      <b-button class="btn btn-lg"
        @click="destory"
        >削除</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import ApptypeBox from '@/components/cms/contents/apptypes/Box.vue';

export default {
  name: 'CmsContentsApplicationsEditView',
  components: {
    'v-apptype-box': ApptypeBox,
  },
  data() {
    return {
    };
  },
  computed: {
    id() {
      return this.$store.state.cmsContentsApptype.id;
    },
    name() {
      return this.$store.state.cmsContentsApptype.name;
    },
    year() {
      return this.$store.state.cmsContentsApptype.year;
    },
    yearOptions() {
      const options = [];
      const now = moment();
      const years = 3;

      for (let i = 0; i < years; i += 1) {
        const year = now.format('YYYY');
        const option = {
          text: year,
          value: year,
        };
        options.push(option);
        now.add(-1, 'years');
      }
      return options;
    },
  },
  methods: {
    async initFetch(promiseFuncs, isEdit) {
      return api.all(promiseFuncs)
        .then((responses) => {
          const { groupList } = responses[0].data;
          this.$store.commit('cmsContentsApptypePage/setFieldGroupList', groupList);
          const fieldList = responses[1].data.list;
          this.$store.commit('cmsContentsApptypePage/setFieldList', fieldList);
          if (isEdit) {
            const dbApptypeData = responses[2].data;
            if (dbApptypeData !== null) {
              this.$store.commit('cmsContentsApptype/setDbData', dbApptypeData.apptype);
              this.$store.commit('cmsContentsApptypePage/setDbData', dbApptypeData.pages);
            } else {
              // データがなかった場合は新規登録ページへ移動する
              this.$router.push({ name: 'cmsContentsItemCreate' });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async save() {
      this.$store.dispatch('page/onWaiting');
      const storeData = this.$store.state.cmsContentsApptype;
      storeData.pages = JSON.stringify(this.$store.state.cmsContentsApptypePage.pages);
      await api.sendForm('/api/contents/applicationTypes/save', storeData)
        .catch((err) => {
          console.error(err);
          return false;
        });
      this.$store.dispatch('page/offWaiting');
    },
    async destory() {
      this.$store.dispatch('page/onWaiting');
      const param = {
        id: this.id,
      };
      await api.send('/api/contents/applicationTypes/destroy', param)
        .catch((err) => {
          console.error(err);
        });
      if (await this.confirm('削除しました。')) {
        this.$router.push({ name: 'cmsContentsApplicationList' });
      }
      this.$store.dispatch('page/offWaiting');
    },
    updateName(value) {
      this.$store.commit('cmsContentsApptype/updateName', value);
    },
    updateYear(value) {
      this.$store.commit('cmsContentsApptype/updateYear', value);
    },
  },
  async created() {
    this.$store.dispatch('page/onLoading', 'CmsContentsApplicationsEditView');
    this.$store.commit('cmsContentsApptype/initApptype');
    const promiseFuncs = [];
    promiseFuncs.push(api.send('/api/contents/fields/group/list'));
    promiseFuncs.push(api.send('/api/contents/fields/list'));
    // 編集の時はルートパラメータ取得し、サーバーにデータを要求する
    const isEdit = this.$route.name === 'cmsContentsApplicationEdit';
    if (isEdit) {
      const { apptypeId } = this.$route.params;
      const param = { apptypeId };
      promiseFuncs.push(api.send('/api/contents/applicationTypes/edit', param));
    }
    await this.initFetch(promiseFuncs, isEdit);
    this.$store.dispatch('page/offLoading', 'CmsContentsApplicationsEditView');
  },
};
</script>

<style scoped>

  .wrap {
    border: solid 1px;
  }

  .unicon {
    cursor : pointer;
  }
</style>
