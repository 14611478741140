<template>
  <div id="mainWrap" class="py-2">
    <b-row class="my-1">
      <b-col sm="3" class="inputLabel">
        <label>ページ名称</label>
      </b-col>
      <b-col sm="5">
        <b-form-input placeholder="入力してください。"
          @input="updateName"
          :value="name"
          ></b-form-input>
      </b-col>
    </b-row>

    <div class="flex">
      <div class="col-sm-7 grid">
        <p class="flex flexCenter mb-1">登録記入項目</p>
        <vue-draggable class="col-12 h100 py-1 mr-5 selectedWrap"
          :list="selectedFieldIdList"
          v-bind="dragOptions">
          <div v-for="(fieldId, index) in selectedFieldIdList" :key="index"
            class="dragItem px-1 py-0 m-1">
            {{fieldList[fieldId].name || ''}} |
            {{CONST_FIELD_TYPE[CONST_FIELD_VALUE[fieldList[fieldId].type]].text}}
          </div>
        </vue-draggable>
      </div>

      <div class="col-sm-5 grid">
        <b-col class="ml-2 px-0">
          <div class="pb-2 pr-1">
            <p class="flex flexCenter mb-1">記入項目検索</p>
          </div>
          <div class="wrap p-2">
            <b-row class="mt-2">
              <b-col sm="3" class="inputLabel pl-0 pr-1">
                <label class="f-12">名称</label>
              </b-col>
              <b-col sm="9">
                <b-form-input
                  :value="fieldName"
                  @input="updateFieldName"
                  placeholder="入力してください。" size="sm"/>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="inputLabel pl-0 pr-1">
                <label class="f-12">入力方法</label>
              </b-col>
              <b-col sm="9">
                <v-form-select
                  :value="fieldType"
                  @input="updateFieldType"
                  :options="this.CONST_FIELD_TYPE"
                  size="sm"/>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="inputLabel pl-0 pr-1">
                <label class="f-12">グループ</label>
              </b-col>
              <b-col sm="9">
                <v-form-select
                  :options="fieldGroupList"
                  :value="fieldGroup"
                  @input="updateFieldGroup"
                  size="sm">
                </v-form-select>
              </b-col>
            </b-row>


            <div class="mt-2 py-2 filteredWrap flex flexCenter">
              <div class="message0">
                <p class="f-14"
                v-if="filterdFieldIdList.length < 1">検索条件を設定してください。</p>
              </div>
              <perfect-scrollbar
                class="w100"
                :options='scrollOption'>
                <vue-draggable class="col-12 h100 w100 scroll"
                  id="dragArea"
                  :list="filterdFieldIdList"
                  v-bind="dragOptions">
                  <div v-for="(fieldId, index) in filterdFieldIdList" :key="index"
                    class="dragItem px-1 py-0 m-1">
                    {{fieldList[fieldId].name || ''}} |
                    {{CONST_FIELD_TYPE[CONST_FIELD_VALUE[fieldList[fieldId].type]].text}}
                  </div>
                </vue-draggable>
              </perfect-scrollbar>
            </div>
          </div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'CmsContentsApptypesPage',
  props: {
    page: {
      type: Number,
    },
  },
  components: {
    'vue-draggable': draggable,
  },
  data() {
    return {
      CONST_FIELD_TYPE: CONST_OPTIONS.FIELD_TYPE,
      CONST_FIELD_VALUE: CONST_OPTIONS.FIELD_VALUE,
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      },
      scrollOption: {
        suppressScrollX: true,
      },
    };
  },
  methods: {
    updateFieldName(value) {
      this.$store.commit('cmsContentsApptypePage/updateFieldName', value);
      this.$store.dispatch('cmsContentsApptypePage/filterFieldId');
    },
    updateFieldGroup(value) {
      this.$store.commit('cmsContentsApptypePage/updateFieldGroup', value);
      this.$store.dispatch('cmsContentsApptypePage/filterFieldId');
    },
    updateFieldType(value) {
      this.$store.commit('cmsContentsApptypePage/updateFieldType', value);
      this.$store.dispatch('cmsContentsApptypePage/filterFieldId');
    },
    sortFilterdFieldIdList(event) {
      this.$store.commit('cmsContentsApptypePage/sortFilterdFieldIdList', event.moved);
    },
    updateName(value) {
      const param = {
        value,
        index: this.dataIndex,
      };
      this.$store.commit('cmsContentsApptypePage/updateName', param);
    },
  },
  computed: {
    dataIndex() {
      return this.page - 1;
    },
    fieldGroupList() {
      return this.$store.state.cmsContentsApptypePage.fieldGroupList;
    },
    fieldName() {
      return this.$store.state.cmsContentsApptypePage.fieldName;
    },
    fieldGroup() {
      return this.$store.state.cmsContentsApptypePage.fieldGroup;
    },
    fieldType() {
      return this.$store.state.cmsContentsApptypePage.fieldType;
    },
    fieldList() {
      return this.$store.state.cmsContentsApptypePage.fieldList;
    },
    selectedFieldIdList() {
      return this.$store.state.cmsContentsApptypePage.pages[this.dataIndex].selectedFieldIdList;
    },
    filterdFieldIdList() {
      return this.$store.state.cmsContentsApptypePage.filterdFieldIdList;
    },
    name() {
      return this.$store.state.cmsContentsApptypePage.pages[this.dataIndex].name;
    },
  },
};
</script>

<style scoped>
  #mainWrap {
    border: solid 1px;
    /* height: 400px;
    overflow-y: scroll; */
    overflow-x: hidden;
  }
  .grid {
    flex: 1;
  }

  .w100 {
    width: 100%;
  }

  .selectedWrap {
    min-height: 340px;
    border: solid 1px;
    overflow-x: hidden;
  }

  .filteredWrap {
    border: solid 1px;
    height: 200px;
    height: 200px;
  }

  #dragArea {
    height: 200px;
  }

  .dragItem {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: 1px solid transparent;
    border-radius: 4px;
    border-color: rgba(51, 45, 45, 0.431);
  }

  .message0 {
    position: absolute;
  }
</style>
