<template>
  <div id="mainWrap" class="py-2">
    <b-row class="mt-2 pb-2">
      <b-col sm="3" class="inputLabel">
        <label>同系統の申請</label>
      </b-col>
      <b-col sm="5">
        <b-form-input
          placeholder="入力してください。"></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-2 pb-2">
      <b-col sm="3" class="inputLabel">
        <label>応募方式</label>
      </b-col>
      <b-col sm="5">
        <b-form-radio-group
          :checked="recruitment_type"
          @change="updateRecruitmentType"
          :options="target"
          name="flavour-1">
        </b-form-radio-group>
      </b-col>
    </b-row>

    <v-form-calendar-datetime
      class="mt-2 pb-2"
      label="公開日時"
      name="barth"
      @change="updateApplicationAnnouncementDatetime"
      :value.sync="application_announcement_datetime"
      itemStyle="line"
      inputClass="w-small"/>

    <v-form-calendar-datetime
      class="mt-2 pb-2"
      label="申請開始日時"
      name="barth"
      @change="updateOpenDatetime"
      :value.sync="open_datetime"
      itemStyle="line"
      inputClass="w-small"/>

    <v-form-calendar-datetime
      class="mt-2 pb-2"
      label="申請終了日時"
      name="barth"
      @change="updateCloseDatetime"
      :value.sync="close_datetime"
      itemStyle="line"
      inputClass="w-small"/>

    <v-form-calendar-datetime
      class="mt-2 pb-2"
      label="一次選考"
      name="barth"
      itemStyle="line"
      inputClass="w-small"/>

    <v-form-calendar-datetime
      class="mt-2 pb-2"
      label="結果発表日時"
      name="barth"
      @change="updateResultAnnouncementDatetime"
      :value.sync="result_announcement_datetime"
      itemStyle="line"
      inputClass="w-small"/>

    <b-row class="mt-2 pb-2">
      <b-col sm="3" class="inputLabel">
        <label>趣旨</label>
      </b-col>
      <b-col sm="8">
        <v-form-textarea
          :noLabel="true"
          :value="purpose"
          @input="updatePurpose"/>
      </b-col>
    </b-row>

    <b-row class="mt-2 pb-2">
      <b-col sm="3" class="inputLabel">
        <label>応募制限</label>
      </b-col>
      <b-col sm="8">
        <v-form-textarea
          :value="restriction"
          @input="updateRestriction"
          :noLabel="true"/>
      </b-col>
    </b-row>

    <b-row class="mt-2 pb-2">
      <b-col sm="3" class="inputLabel">
        <label>助成金額</label>
      </b-col>
      <b-col sm="5">
        <b-input-group>
        <b-form-input
          min="0"
          type="number"
          @input="updateMoney"
          :value="money"
          placeholder="入力してください。"/>
          <b-input-group-append
            class="ml-1 flex flexMiddle">
            万円
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row class="mt-2 pb-2">
      <b-col sm="3" class="inputLabel">
        <label>助成件数</label>
      </b-col>
      <b-col sm="5">
        <b-input-group>
          <b-form-input
            min="0"
            type="number"
            @input="updateNumber"
            :value="number"
            placeholder="入力してください。"/>
          <b-input-group-append
            class="ml-1 flex flexMiddle">
            件
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row class="mt-2 pb-2">
      <b-col sm="3" class="inputLabel">
        <label>募集要領</label>
      </b-col>
      <b-col sm="5">
        <b-input-group v-if="hasRequirementsFile">
          <b-link class="mb-1"
            @click="downloadRequirementsFile">ファイル名変数</b-link>
            <b-form-checkbox
              @change="toggleDoDeleteFile"
              :checked="do_delete_file"
              class="ml-3"
              switches>削除</b-form-checkbox>
        </b-input-group>

        <b-form-file
          :value="requirements_file"
          @input="updateRequirementsFile"
          placeholder=""
          browse-text="参照"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '@/modules/api';
import download from '@/modules/download';
import CONST_APPTYPE from '@/constants/apptypes';

export default {
  name: 'CmsContentsApptypesBase',
  components: {
  },
  data() {
    return {
      selected: [],
      target: CONST_APPTYPE.APP_TYPE_TARGET,
    };
  },
  methods: {
    async downloadRequirementsFile() {
      const param = {
        apptypeId: this.$store.state.cmsContentsApptype.id,
      };
      this.$store.dispatch('page/onWaiting');
      const response = await api.send('/api/contents/applicationTypes/download/requirementsFile', param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, this.requirements_file_name);
    },
    updateRequirementsFile(file) {
      this.$store.commit('cmsContentsApptype/updateRequirementsFile', file);
    },
    updatePurpose(value) {
      this.$store.commit('cmsContentsApptype/updatePurpose', value);
    },
    updateRecruitmentType(value) {
      this.$store.commit('cmsContentsApptype/updateRecruitmentType', value);
    },
    updateApplicationAnnouncementDatetime(value) {
      this.$store.commit('cmsContentsApptype/updateApplicationAnnouncementDatetime', value);
    },
    updateOpenDatetime(value) {
      this.$store.commit('cmsContentsApptype/updateOpenDatetime', value);
    },
    updateCloseDatetime(value) {
      this.$store.commit('cmsContentsApptype/updateCloseDatetime', value);
    },
    updateResultAnnouncementDatetime(value) {
      this.$store.commit('cmsContentsApptype/updateResultAnnouncementDatetime', value);
    },
    updateRestriction(value) {
      this.$store.commit('cmsContentsApptype/updateRestriction', value);
    },
    updateMoney(value) {
      this.$store.commit('cmsContentsApptype/updateMoney', value);
    },
    updateNumber(value) {
      this.$store.commit('cmsContentsApptype/updateNumber', value);
    },
    toggleDoDeleteFile() {
      this.$store.commit('cmsContentsApptype/toggleDoDeleteFile');
    },
  },
  computed: {
    recruitment_type() {
      return this.$store.state.cmsContentsApptype.recruitment_type;
    },
    application_announcement_datetime() {
      return this.$store.state.cmsContentsApptype.application_announcement_datetime;
    },
    open_datetime() {
      return this.$store.state.cmsContentsApptype.open_datetime;
    },
    close_datetime() {
      return this.$store.state.cmsContentsApptype.close_datetime;
    },
    result_announcement_datetime() {
      return this.$store.state.cmsContentsApptype.result_announcement_datetime;
    },
    requirements_file() {
      return this.$store.state.cmsContentsApptype.requirements_file;
    },
    hasRequirementsFile() {
      return this.$store.state.cmsContentsApptype.hasRequirementsFile;
    },
    requirements_file_name() {
      return this.$store.state.cmsContentsApptype.requirements_file_name;
    },
    do_delete_file() {
      return this.$store.state.cmsContentsApptype.do_delete_file;
    },
  },
};
</script>

<style scoped>
  #mainWrap {
    border: solid 1px;
    height: 440px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

</style>
